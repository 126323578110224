<template>
  <div class="main-wrapper">
    <div class="title">
      <div class="titleData">{{headline.goods_name}}商品数据</div>
      <p>(近30天数据)</p>
    </div>
    <div class="main-content">
      <el-form
        :model="ProductDataForm"
        label-width="130px"
        ref="ProductDataForm"
        class="demo-ruleForm"
        :rules="datarules"
      >
        <div class="left-box">
          <div class="left-top">
            <el-form-item label="销量" prop="sales_count" class="other-item">
              <el-input
                ref="name"
                v-model="ProductDataForm.sales_count"
                style="width: 90%"
                placeholder="请输入销量范围在1000-10000"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="波动范围"
              prop="sales_left"
              class="other-item space"
            >
              <el-input
                ref="name"
                v-model="ProductDataForm.sales_left"
                style="width: 38%"
                @blur="clickInputFocus_sales_left(ProductDataForm.sales_left)"
              ></el-input>
              <span style="padding: 0 3.5%">% - </span>
              <el-input
                ref="sales_right"
                v-model="ProductDataForm.sales_right"
                style="width: 38%"
                @blur="clickInputFocus_sales_right(ProductDataForm.sales_right)"
              ></el-input>
              <span style="padding-left: 4%">%</span>
            </el-form-item>
          </div>
          <div class="left-cen">
            <el-form-item
              label="成交金额指数"
              prop="turnover_count"
              class="other-item"
            >
              <el-input
                ref="name"
                v-model="ProductDataForm.turnover_count"
                style="width: 90%"
                placeholder="请输入成交金额指数范围在100000-1000000"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="波动范围"
              prop="turnover_left"
              class="other-item space"
            >
              <el-input
                ref="name"
                v-model="ProductDataForm.turnover_left"
                style="width: 38%"
                @blur="clickInputFocus_turnover_left(ProductDataForm.turnover_left)"
              ></el-input>
              <span style="padding: 0 3.5%">% - </span>
              <el-input
                ref="turnover_right"
                v-model="ProductDataForm.turnover_right"
                style="width: 38%"
                @blur="clickInputFocus_turnover_right(ProductDataForm.turnover_right)"
              ></el-input>
              <span style="padding-left: 4%">%</span>
            </el-form-item>
          </div>
          <div class="left-foot">
            <el-form-item
              label="访客人数"
              prop="visitor_count"
              class="other-item"
            >
              <el-input
                ref="name"
                v-model="ProductDataForm.visitor_count"
                style="width: 90%"
                 placeholder="请输入访客人数范围在10000-100000"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="波动范围"
              prop="visitor_left"
              class="other-item space"
            >
              <el-input
                ref="name"
                v-model="ProductDataForm.visitor_left"
                style="width: 38%"
                @blur="clickInputFocus_visitor_left(ProductDataForm.visitor_left)"
              ></el-input>
              <span style="padding: 0 3.5%">% - </span>
              <el-input
                ref="visitor_right"
                v-model="ProductDataForm.visitor_right"
                style="width: 38%"
                @blur="clickInputFocus_visitor_right(ProductDataForm.visitor_right)"
              ></el-input>
              <span style="padding-left: 4%">%</span>
            </el-form-item>
          </div>
        </div>
        <div class="middle-box">
          <div class="left-top">
            <el-form-item label="浏览量" prop="view_count" class="other-item">
              <el-input
                ref="name"
                v-model="ProductDataForm.view_count"
                style="width: 90%"
                placeholder="请输入浏览量范围在10000-100000"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="波动范围"
              prop="view_left"
              class="other-item space"
            >
              <el-input
                ref="name"
                v-model="ProductDataForm.view_left"
                style="width: 38%"
                @blur="clickInputFocus_view_left(ProductDataForm.view_left)"
              ></el-input>
              <span style="padding: 0 3.5%">% - </span>
              <el-input
                ref="view_right"
                v-model="ProductDataForm.view_right"
                style="width: 38%"
                @blur="clickInputFocus_view_right(ProductDataForm.view_right)"
              ></el-input>
              <span style="padding-left: 4%">%</span>
            </el-form-item>
          </div>
          <div class="left-cen">
            <el-form-item
              label="成交单量指数"
              prop="single_count"
              class="other-item"
            >
              <el-input
                ref="name"
                v-model="ProductDataForm.single_count"
                style="width: 90%"
                 placeholder="请输入成交单量指数范围在1000-10000"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="波动范围"
              prop="single_left"
              class="other-item space"
            >
              <el-input
                ref="name"
                v-model="ProductDataForm.single_left"
                style="width: 38%"
                @blur="clickInputFocus_single_left(ProductDataForm.single_left)"
              ></el-input>
              <span style="padding: 0 3.5%">% - </span>
              <el-input
                ref="single_right"
                v-model="ProductDataForm.single_right"
                style="width: 38%"
                @blur="clickInputFocus_single_right(ProductDataForm.single_right)"
              ></el-input>
              <span style="padding-left: 4%">%</span>
            </el-form-item>
          </div>
        </div>
        <div class="right-box">
          <div class="left-top">
            <el-form-item
              label="曝光量"
              prop="exposure_count"
              class="other-item"       
            >
              <el-input
                ref="name"
                v-model="ProductDataForm.exposure_count"
                style="width: 90%"
                  placeholder="请输入曝光量范围在100000-1000000"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="波动范围"
              prop="exposure_left"
              class="other-item space"
            >
              <el-input
                ref="name"
                v-model="ProductDataForm.exposure_left"
                style="width: 38%"
                @blur="clickInputFocus_exposure_left(ProductDataForm.exposure_left)"
              ></el-input>
              <span style="padding: 0 3.5%">% - </span>
              <el-input
                ref="exposure_right"
                v-model="ProductDataForm.exposure_right"
                style="width: 38%"
                @blur="clickInputFocus_exposure_right(ProductDataForm.exposure_right)"
              ></el-input>
              <span style="padding-left: 4%">%</span>
            </el-form-item>
          </div>
          <div class="left-cen">
            <el-form-item
              label="搜索人数"
              prop="search_count"
              class="other-item"
              
            >
              <el-input
                ref="name"
                v-model="ProductDataForm.search_count"
                style="width: 90%"
                 placeholder="请输入搜索人数范围在100000-1000000"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="波动范围"
              prop="search_left"
              class="other-item space"
            >
              <el-input
                ref="name"
                v-model="ProductDataForm.search_left"
                style="width: 38%"
                @blur="clickInputFocus_search_left(ProductDataForm.search_left)"
              ></el-input>
              <span style="padding: 0 3.5%">% - </span>
              <el-input
                ref="search_right"
                v-model="ProductDataForm.search_right"
                style="width: 38%"
                @blur="clickInputFocus_search_right(ProductDataForm.search_right)"
              ></el-input>
              <span style="padding-left: 4%">%</span>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <div class="footer-button">
      <el-button type="primary" @click="backTo">上一步</el-button>
      <el-button type="primary" @click="next">生成数据</el-button>
    </div>
  </div>
</template>

<script>
import { createGoodsData } from "@/utils/apis";
export default {
  data() {
    //商品销量波动验证
    var sales = (rule, value, callback) => {
      let sales_right = this.$refs.sales_right.value;
      if (!value) {
        callback(new Error("请输入左边波动范围"));
      } else if (!sales_right) {
        callback(new Error("请输入右边波动范围"));
      }else{
         callback();
      }
    };
    //商品浏览量波动验证
    var view = (rule, value, callback) => {
      let view_right = this.$refs.view_right.value;
      if (!value) {
        callback(new Error("请输入左边波动范围"));
      } else if (!view_right) {
        callback(new Error("请输入右边波动范围"));
      }else{
         callback();
      }
    };
    //商品成交单量波动验证
    var single = (rule, value, callback) => {
      let single_right = this.$refs.single_right.value;
      if (!value) {
        callback(new Error("请输入左边波动范围"));
      } else if (!single_right) {
        callback(new Error("请输入右边波动范围"));
      }else{
         callback();
      }
    };
    //商品曝光量波动验证
    var exposure = (rule, value, callback) => {
      let exposure_right = this.$refs.exposure_right.value;
      if (!value) {
        callback(new Error("请输入左边波动范围"));
      } else if (!exposure_right) {
        callback(new Error("请输入右边波动范围"));
      }else{
         callback();
      }
    };
    //商品成交金额指数波动验证
    var turnover = (rule, value, callback) => {
      let turnover_right = this.$refs.turnover_right.value;
      if (!value) {
        callback(new Error("请输入左边波动范围"));
      } else if (!turnover_right) {
        callback(new Error("请输入右边波动范围"));
      }else{
         callback();
      }
    };
    //商品商品搜索人数波动验证
    var search = (rule, value, callback) => {
      let search_right = this.$refs.search_right.value;
      if (!value) {
        callback(new Error("请输入左边波动范围"));
      } else if (!search_right) {
        callback(new Error("请输入右边波动范围"));
      }else{
         callback();
      }
    };
    //商品商品访客人数波动验证
    var visitor = (rule, value, callback) => {
      let visitor_right = this.$refs.visitor_right.value;
      if (!value) {
        callback(new Error("请输入左边波动范围"));
      } else if (!visitor_right) {
        callback(new Error("请输入右边波动范围"));
      }else{
         callback();
      }
    };
    return {
      ProductDataForm: {
        sales_count: "", //商品销量
        sales_left: "", //商品销量左波动
        sales_right: "", //商品销量右波动
        view_count: "", //商品浏览量
        view_left: "", //商品浏览量
        view_right: "", //商品浏览量右波动
        exposure_count: "", //商品曝光量
        exposure_left: "", //商品曝光量左波动
        exposure_right: "", //商品曝光量右波动
        turnover_count: "", //成交金额指数
        turnover_left: "", //成交金额指数左波动
        turnover_right: "", //成交金额指数右波动
        single_count: "", //成交单量指数
        single_left: "", //成交单量左波动
        single_right: "", //成交单量右波动
        search_count: "", //搜索人数
        search_left: "", //搜索人数左波动
        search_right: "", //搜索人数右波动
        visitor_count: "", //访客人数
        visitor_left: "", //访客人数左波动
        visitor_right: "", //访客人数右波动
      },
      thirty_data: "",
      headline:'',
      sum_id:'',
      datarules: {
        sales_count: [
          { required: true, message: "请输入商品销量", trigger: "blur" },
          {
            min: 4,
            max: 5,
            message: "商品销量在1000-10000之间",
            trigger: "blur",
          },
        ],
        sales_left: [{ required: true, validator: sales }],
        view_count: [
          { required: true, message: "请输入商品浏览量", trigger: "blur" },
          {
            min: 5,
            max: 6,
            message: "商品浏览量在10000-100000之间",
            trigger: "blur",
          },
        ],
        view_left: [{ required: true, validator: view }],

        exposure_count: [
          { required: true, message: "请输入商品曝光量", trigger: "blur" },
          {
            min: 6,
            max: 7,
            message: "商品曝光量在100000-1000000之间",
            trigger: "blur",
          },
        ],
        exposure_left: [{ required: true, validator: exposure }],

        single_count: [
          {
            required: true,
            message: "请输入商品成交单量指数",
            trigger: "blur",
          },
          {
            min: 4,
            max: 5,
            message: "商品成交单量指数在1000-10000之间",
            trigger: "blur",
          },
        ],
        single_left: [{ required: true, validator: single }],

        search_count: [
          { required: true, message: "请输入商品搜索人数", trigger: "blur" },
          {
            min: 6,
            max: 7,
            message: "商品销量在100000-1000000之间",
            trigger: "blur",
          },
        ],
        search_left: [{ required: true, validator: search }],

        turnover_count: [
          {
            required: true,
            message: "请输入商品成交金额指数",
            trigger: "blur",
          },
          {
            min: 6,
            max: 7,
            message: "商品成交金额指数在100000-1000000之间",
            trigger: "blur",
          },
        ],
        turnover_left: [{ required: true, validator: turnover }],

        visitor_count: [
          { required: true, message: "请输入商品访客人数", trigger: "blur" },
          {
            min: 5,
            max: 6,
            message: "商品访客人数在10000-100000之间",
            trigger: "blur",
          },
        ],
        visitor_left: [{ required: true, validator: visitor }],
      },
    };
  },
  created(){
     this.headline = JSON.parse(localStorage.getItem("message"))
     this.$bus.$on("title", () => {        
        this.headline = JSON.parse(localStorage.getItem("message"))
    })
    this.editEstablish()
  },
  methods: {
    //编辑
    editEstablish(){
      if (this.$route.query.id) {  
        let eiitList = JSON.parse(localStorage.getItem("commodityeditData"))
        this.ProductDataForm.sales_count = eiitList.before_create_goods_data.goods_sales_array.count
        this.ProductDataForm.sales_left = eiitList.before_create_goods_data.goods_sales_array.left
        this.ProductDataForm.sales_right = eiitList.before_create_goods_data.goods_sales_array.right
        this.ProductDataForm.turnover_count = eiitList.before_create_goods_data.goods_turnover_array.count
        this.ProductDataForm.turnover_left = eiitList.before_create_goods_data.goods_turnover_array.left
        this.ProductDataForm.turnover_right = eiitList.before_create_goods_data.goods_turnover_array.right
        this.ProductDataForm.visitor_count = eiitList.before_create_goods_data.goods_visitor_array.count
        this.ProductDataForm.visitor_left = eiitList.before_create_goods_data.goods_visitor_array.left
        this.ProductDataForm.visitor_right = eiitList.before_create_goods_data.goods_visitor_array.right
        this.ProductDataForm.view_count = eiitList.before_create_goods_data.goods_view_array.count
        this.ProductDataForm.view_left = eiitList.before_create_goods_data.goods_view_array.left
        this.ProductDataForm.view_right = eiitList.before_create_goods_data.goods_view_array.right
        this.ProductDataForm.single_count = eiitList.before_create_goods_data.goods_single_array.count
        this.ProductDataForm.single_left = eiitList.before_create_goods_data.goods_single_array.left
        this.ProductDataForm.single_right = eiitList.before_create_goods_data.goods_single_array.right
        this.ProductDataForm.exposure_count = eiitList.before_create_goods_data.goods_exposure_array.count
        this.ProductDataForm.exposure_left = eiitList.before_create_goods_data.goods_exposure_array.left
        this.ProductDataForm.exposure_right = eiitList.before_create_goods_data.goods_exposure_array.right
        this.ProductDataForm.search_count = eiitList.before_create_goods_data.goods_search_array.count
        this.ProductDataForm.search_left = eiitList.before_create_goods_data.goods_search_array.left
        this.ProductDataForm.search_right = eiitList.before_create_goods_data.goods_search_array.right
      }
    },
    clickInputFocus_sales_left(val){
      if(val <-99){
        this.ProductDataForm.sales_left = -99
      }
    },
    clickInputFocus_sales_right(val){
       if(val >100){
        this.ProductDataForm.sales_right = 100
      }
    },
    clickInputFocus_turnover_left(val){
      if(val <-99){
        this.ProductDataForm.turnover_left = -99
      }
    },
    clickInputFocus_turnover_right(val){
      if(val >100){
        this.ProductDataForm.turnover_right = 100
      }
    },
    clickInputFocus_visitor_left(val){
       if(val <-99){
        this.ProductDataForm.visitor_left = -99
      }
    },
    clickInputFocus_visitor_right(val){
       if(val >100){
        this.ProductDataForm.visitor_right = 100
      }
    },
    clickInputFocus_view_left(val){
       if(val <-99){
        this.ProductDataForm.view_left = -99
      }
    },
    clickInputFocus_view_right(val){
        if(val >100){
        this.ProductDataForm.view_right = 100
      }
    },
    clickInputFocus_single_left(val){
       if(val <-99){
        this.ProductDataForm.single_left = -99
      }
    },
    clickInputFocus_single_right(val){
       if(val >100){
        this.ProductDataForm.single_right = 100
      }
    },
    clickInputFocus_exposure_left(val){
       if(val <-99){
        this.ProductDataForm.exposure_left = -99
      }
    },
    clickInputFocus_exposure_right(val){
       if(val >100){
        this.ProductDataForm.exposure_right = 100
      }
    },
    clickInputFocus_search_left(val){
      if(val <-99){
        this.ProductDataForm.search_left = -99
      }
    },
    clickInputFocus_search_right(val){
        if(val >100){
        this.ProductDataForm.search_right = 100
      }
    },


    // 返回上一步
    backTo() {
       if(this.$route.query.id){
          this.$router.push({
            path: "/liveStreaming/newComManagement/message",query: { id: this.$route.query.id }
          });
          this.$bus.$emit("updateData");
       }else{
          this.$router.push({
            path: "/liveStreaming/newComManagement/message",
          });
       }    
    },
    //下一步
    next() {
      this.$refs["ProductDataForm"].validate((valid) => {
        if (valid) {
           let param = {
        
            goods_sales_array: {
              count: this.ProductDataForm.sales_count,
              left: this.ProductDataForm.sales_left,
              right: this.ProductDataForm.sales_right,
            },
            goods_view_array: {
              count: this.ProductDataForm.view_count,
              left: this.ProductDataForm.view_left,
              right: this.ProductDataForm.view_right,
            },
            goods_exposure_array: {
              count: this.ProductDataForm.exposure_count,
              left: this.ProductDataForm.exposure_left,
              right: this.ProductDataForm.exposure_right,
            },
            goods_turnover_array: {
              count: this.ProductDataForm.turnover_count,
              left: this.ProductDataForm.turnover_left,
              right: this.ProductDataForm.turnover_right,
            },
            goods_single_array: {
              count: this.ProductDataForm.single_count,
              left: this.ProductDataForm.single_left,
              right: this.ProductDataForm.single_right,
            },
            goods_search_array: {
              count: this.ProductDataForm.search_count,
              left: this.ProductDataForm.search_left,
              right: this.ProductDataForm.search_right,
            },
            goods_visitor_array: {
              count: this.ProductDataForm.visitor_count,
              left: this.ProductDataForm.visitor_left,
              right: this.ProductDataForm.visitor_right,
            },
          };
          createGoodsData(param)
            .then((res) => {
              if (res.code == 200) {
                this.thirty_data = res.data;
                localStorage.setItem("create_goods_data", JSON.stringify(param));   
                localStorage.setItem("thirtyData", JSON.stringify(this.thirty_data));
                this.$router.push({
                  path: "/liveStreaming/newComManagementCreate",query: { id: this.$route.query.id }
                });
                this.$bus.$emit("updateData");
                
                
            
              }
            })
            .catch((err) => {
              console.error("err", err);
            });
        }
        
      });
    },
  },
};
</script>


<style lang="scss" scoped>
.main-wrapper {
  height: 100%;
  padding: 0 30px;
  min-width: 800px;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .title {
    text-align: center;
    padding-bottom: 30px;
    .titleData {
      font-weight: bold;
    }
  }
  .footer-button {
    text-align: center;
    padding-top: 35px;
  }
  .main-content {
    display: flex;
    .space {
      white-space: nowrap;
    }
    .left-box {
      width: 33%;
      .left-cen {
        margin-top: 80px;
      }
      .left-foot {
        margin-top: 80px;
      }
    }
    .middle-box {
      width: 33%;
      .left-cen {
        margin-top: 80px;
      }
    }
    .right-box {
      width: 33%;
      .left-cen {
        margin-top: 80px;
      }
    }
    .demo-ruleForm {
      display: flex;
      width: 100%;
    }
  }
}
</style>